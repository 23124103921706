import React from 'react';

// Utils
import { SCHEMA_TYPE_MULTI_ENUM, SCHEMA_TYPE_TEXT } from '../../util/types';
import {
  isFieldForCategory,
  pickCategoryFields,
  pickCustomFieldProps,
} from '../../util/fieldHelpers.js';

import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionTextMaybe from './SectionTextMaybe';

/**
 * Renders custom listing fields.
 * - SectionDetailsMaybe is used if schemaType is 'enum', 'long', or 'boolean'
 * - SectionMultiEnumMaybe is used if schemaType is 'multi-enum'
 * - SectionTextMaybe is used if schemaType is 'text'
 *
 * @param {*} props include publicData, metadata, listingFieldConfigs, categoryConfiguration
 * @returns React.Fragment containing aforementioned components
 */
const CustomListingFields = props => {
  const { publicData, metadata, listingFieldConfigs, categoryConfiguration } = props;

  const { key: categoryPrefix, categories: listingCategoriesConfig } = categoryConfiguration;
  const categoriesObj = pickCategoryFields(publicData, categoryPrefix, 1, listingCategoriesConfig);
  const currentCategories = Object.values(categoriesObj);

  const isFieldForSelectedCategories = fieldConfig => {
    const isTargetCategory = isFieldForCategory(currentCategories, fieldConfig);
    return isTargetCategory;
  };

  const level1options = categoryConfiguration.categories.map(c => ({
    option: c.id,
    label: c.name,
  }));

  const level2options = categoryConfiguration.categories
    .map(c => c.subcategories)
    .reduce((acc, subcategories) => acc.concat(subcategories), [])
    .map(c => ({ option: c.id, label: c.name }));

  const level3options = categoryConfiguration.categories
    .map(c => c.subcategories)
    .reduce((acc, subcategories) => acc.concat(subcategories), [])
    .map(c => c.subcategories)
    .reduce((acc, subcategories) => acc.concat(subcategories), [])
    .map(c => ({ option: c.id, label: c.name }));

  const categoriesConfig = [
    {
      key: 'categoryLevel1',
      scope: 'public',
      schemaType: 'enum',
      enumOptions: level1options,
      showConfig: {
        label: 'Category',
        isDetail: true,
      },
    },

    {
      key: 'categoryLevel2',
      scope: 'public',
      schemaType: 'enum',
      enumOptions: level2options,
      showConfig: {
        label: 'Subcategory',
        isDetail: true,
      },
    },
    {
      key: 'categoryLevel3',
      scope: 'public',
      schemaType: 'enum',
      enumOptions: level3options,
      showConfig: {
        label: 'Brand',
        isDetail: true,
      },
    },
  ];

  const extendedListingFieldConfigs = [...categoriesConfig, ...listingFieldConfigs];

  const propsForCustomFields =
    pickCustomFieldProps(
      publicData,
      metadata,
      extendedListingFieldConfigs,
      'listingType',
      isFieldForSelectedCategories
    ) || [];

  return (
    <>
      <SectionDetailsMaybe
        {...props}
        listingFieldConfigs={extendedListingFieldConfigs}
        isFieldForCategory={isFieldForSelectedCategories}
      />
      {propsForCustomFields
        .filter(item => item.key !== 'model')
        .map(customFieldProps => {
          const { schemaType, ...fieldProps } = customFieldProps;
          if (fieldProps.key === 'report') {
            fieldProps.standalone = true;
          }
          return schemaType === SCHEMA_TYPE_MULTI_ENUM ? (
            <SectionMultiEnumMaybe {...fieldProps} />
          ) : schemaType === SCHEMA_TYPE_TEXT ? (
            <SectionTextMaybe {...fieldProps} />
          ) : null;
        })}
    </>
  );
};

export default CustomListingFields;
